<template>
  <nav class="navbar">
    <div class="logo">
      <!-- <img
        :src="require('@/page/namvaytien/components/img/logo.png')"
        :alt="logo"
        class="logoImg"
      /> -->
      <span class="title">ANHIT INFORMATION SERVICE COMPANY LIMITED</span>
    </div>
    <ul class="nav-links">
      <li
        :class="{ 'active-link': isActive('/') }">
        <router-link to="/" class="nav-link1">
          <span class="routeText1">Giới thiệu khoản vay</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/about' }">
        <router-link to="/about" class="nav-link2">
          <span class="routeText2">Về chúng tôi</span>
        </router-link>
      </li>
      <li
        :class="{ 'active-link': $route.path === '/question' }">
        <router-link to="/question" class="nav-link3">
          <span class="routeText3">Các câu hỏi thường gặp</span>
        </router-link>
      </li>
      <li :class="{ 'active-link': $route.path === '/protocol' }">
        <router-link to="/protocol" class="nav-link4">
          <span class="routeText4">Thỏa thuận quyền riêng tư</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      logo: "NAM A ASSET MANAGEMENT COMPANY LIMITED",
      backgroundImage: "@/page/namvaytien/components/img/Rectangle.png",
      link1Color: "white", 
    };
  },
  methods: {
   
    isActive(route) {
      return this.$route.path === route;
    },
  },
};
</script>
<style scoped>
.navbar {
  /* height: 120px; */
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 10px 10px; */
  background-color: white;
  min-width: 1400px;
  /* max-width: 1920px; */
  max-width: 1400px;
  z-index: 100;
}

.logo {
  font-weight: bold;
  color: black;
  display: flex; /* 让 Logo 内容成为一个 flex 容器，使图片和文字水平排列 */
  align-items: center; /* 垂直居中对齐 */
  padding-left: 10px;
}

.logoImg {
  width: 50px;
  height: 50px;
}
.title {
  width: 430px;
  height: 24px;
  font-size: 16px;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #000000;
  line-height: 23px;
  padding-left: 16px;
}
.routeText1,
.routeText2,
.routeText3,
.routeText4,
.routeText5 {
  font-size: 16px;
  font-family: SF Pro, SF Pro;
  display: flex; /* 设置为弹性盒子 */
  align-items: center; /* 垂直居中 */
  text-align: center;
  line-height: 55px;
}
.routeText1 {
  width: 180px;
  padding-left: 17px;
  padding-right: 8px;
}
.routeText2 {
  width: 120px;
  padding-left: 15px;
  padding-right: 8px;
}
.routeText3 {
  width: 220px;
  padding-left: 30px;
  padding-right: 8px;
}
.routeText4 {
  width: 250px;
  padding-left: 30px;
  padding-right: 8px;
  text-align: center;
}
.routeText5 {
  width: 200px;
  padding-left: 8px;
  padding-right: 8px;
  text-align: center;
}
.nav-links {
  list-style: none;
  display: flex;
}

.nav-links li {
  height: 72px;
}

.nav-link1,
.nav-link2,
.nav-link3,
.nav-link4,
.nav-link5 {
  text-decoration: none;
 /* 将链接文字转为大写 */
  /* text-transform: uppercase; */
  font-size: 16px;
  font-family: SF Pro, SF Pro;
}

.nav-link1 {
  height: 72px;
  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #626673;
  line-height: 39px;
}

.nav-link2 {
  height: 72px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #626673;
  line-height: 39px;
}
.nav-link3 {
  height: 72px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #626673;
  line-height: 39px;
}
.nav-link4 {
  height: 72px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #626673;
  line-height: 39px;
}
.nav-link5 {
  height: 72px;

  font-family: SF Pro, SF Pro;
  font-weight: 500;
  color: #626673;
  line-height: 39px;
}
.nav-links li {
  padding: 5px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  height: 72px;
}
.active-link {
  /* background: url("../page/namvaytien/components/img/Rectangle.png"); */
  background-color: #EBF7FF;
  background-size: 100% 100%; /* 背景图片充满整个容器 */
  background-repeat: no-repeat;
}
.active-link .routeText {
  color: white;
}
.active-link span{
  color: #428CBB;
}

</style>