<template>
  <div class="imgBox min-w-1400">
    <div class="imgBox1 min-w-1400">
      <div class="img1 max-w-1400">
        <!-- <img :src="importedImg1" :alt="altText" class="imgItem1" /> -->
      </div>
    </div>
    <div class="imgBox2 min-w-1400">
      <div class="img2 max-w-1400" >
       <h1>CHÍNH SÁCH BẢO MẬT</h1>
       <div class="textBox" style="white-space: pre-wrap">{{text}}</div>
       
      </div>
    </div>
 
  </div>
</template>

<script>
import importedImg1 from "@/page/agorarecursos/components/img/Rectangle1.png";

export default {
  data() {
    return {
      importedImg1: importedImg1,
      text:`Giới thiệu
xin hãy đọc kỹ càng những điều khoản và điều kiện này. Họ hủy bỏ và thay thế mọi phiên bản trước đó. Bằng cách đăng ký hoặc sử dụng Dịch vụ của chúng tôi (như được định nghĩa bên dưới), bạn đồng ý bị ràng buộc bởi các Điều khoản và Điều kiện này.
sự định nghĩa
Các thuật ngữ viết hoa có ý nghĩa sau trong các điều khoản và điều kiện này:
Dịch vụ có nghĩa là các dịch vụ được cung cấp thông qua trang web của chúng tôi và các ứng dụng khác nhau cũng như mọi phần mềm và dịch vụ liên quan.
Nhà cung cấp nghĩa là chủ sở hữu/người điều hành trang web hoặc dịch vụ của bên thứ ba đó.
Người dùng là người sử dụng Dịch vụ của chúng tôi (dù có đăng ký với chúng tôi hay không).
Thay đổi về điều khoản và điều kiện
Chúng tôi có thể thay đổi các điều khoản và điều kiện này và đăng các phiên bản sửa đổi trên trang web của chúng tôi 21 ngày trước khi chúng có hiệu lực. Vui lòng kiểm tra trang web của chúng tôi theo thời gian. Chúng tôi khuyên bạn nên đánh dấu trang này để đảm bảo bạn biết về bất kỳ thay đổi nào. Nếu bạn tiếp tục sử dụng Dịch vụ của chúng tôi sau ngày có hiệu lực đã nêu, bạn sẽ bị ràng buộc bởi Thỏa thuận sửa đổi.
sử dụng dịch vụ của chúng tôi
Chúng tôi cấp cho người dùng các quyền cá nhân hạn chế để sử dụng Dịch vụ của chúng tôi theo các Điều khoản và Điều kiện này
Bạn không được cung cấp bất kỳ dữ liệu cá nhân nào của người khác (tức là thông tin nhận dạng hoặc liên hệ với một người) thông qua Dịch vụ của chúng tôi trừ khi người đó trên 18 tuổi và bạn đã nhận được sự đồng ý rõ ràng bằng văn bản của người đó;
Tài khoản của bạn trên Dịch vụ của chúng tôi chỉ dành cho mục đích sử dụng cá nhân của bạn và không thể chuyển nhượng được. Bạn không được ủy quyền hoặc cho phép bất kỳ người nào khác sử dụng tài khoản của bạn. Bạn phải thực hiện các bước hợp lý để bảo vệ và giữ bí mật mật khẩu cũng như thông tin nhận dạng hoặc tài khoản khác của mình. Bạn phải thông báo cho chúng tôi ngay lập tức về bất kỳ hành vi vi phạm bảo mật rõ ràng nào, chẳng hạn như mất mát, trộm cắp, sử dụng sai hoặc tiết lộ hoặc sử dụng trái phép mật khẩu của bạn. Bạn chịu trách nhiệm đối với các bên thứ ba sử dụng tài khoản hoặc danh tính của bạn (trừ khi chúng tôi có lỗi).
Bạn phải tuân thủ kịp thời mọi yêu cầu hoặc hướng dẫn hợp lý mà chúng tôi đưa ra liên quan đến Dịch vụ.
Bạn phải đảm bảo rằng tất cả thông tin bạn cung cấp là chính xác, không gây hiểu nhầm và bạn cập nhật thông tin đó để duy trì tính chính xác của thông tin đó.

Bất kỳ hợp đồng hoặc giao dịch nào khác về việc bán hoặc cung cấp hàng hóa hoặc dịch vụ liên quan đến trang web hoặc dịch vụ của bên thứ ba đều là giữa bạn và Nhà cung cấp. Bạn thừa nhận rằng mọi khiếu nại pháp lý liên quan đến các giao dịch đó sẽ chống lại Nhà cung cấp chứ không chống lại chúng tôi. Mọi thắc mắc hoặc khiếu nại phải được chuyển đến nhà cung cấp.
nội dung của chúng tôi
Bất kỳ nội dung nào chúng tôi tự cung cấp (chẳng hạn như các bài viết về các chủ đề khác nhau) chỉ nhằm mục đích hướng dẫn rất chung chung nhưng chúng tôi không thể đảm bảo rằng nội dung đó chính xác hoặc cập nhật. Bạn phải tự đưa ra những yêu cầu phù hợp, bao gồm cả tính chính xác và phù hợp với mục đích của bạn, đồng thời nhận được lời khuyên chuyên môn hoặc lời khuyên khác phù hợp trước khi hành động dựa trên bất kỳ thông tin nào như vậy. Sự phụ thuộc của bạn vào thông tin đó là rủi ro của riêng bạn.
Hoạt động dịch vụ của chúng tôi
Chúng tôi không thể đảm bảo rằng trang web sẽ không bị gián đoạn hoặc không có lỗi. Chúng tôi không chịu trách nhiệm pháp lý đối với những sai sót hoặc gián đoạn. Chúng tôi có quyền đình chỉ Trang web để sửa chữa, bảo trì, cải tiến hoặc vì lý do kỹ thuật khác.
sở hữu trí tuệ
Tất cả nhãn hiệu, logo, nội dung, bố cục, đồ họa, hình ảnh, ảnh chụp, hoạt ảnh, video, văn bản và phần mềm được sử dụng trên Dịch vụ đều là tài sản trí tuệ của chúng tôi hoặc tài sản trí tuệ của các đối tác của chúng tôi. Bạn có thể xem tài liệu đó trên màn hình và in một bản sao chỉ để sử dụng cho cá nhân bạn. Bạn không được phép sử dụng, cấp phép lại, truy xuất, hiển thị, sửa đổi, sao chép, in, bán, phân phối, tải xuống, thuê, đảo ngược kỹ thuật (trừ khi được pháp luật hiện hành cho phép) hoặc tạo các đoạn trích của các tài liệu đó mà không có sự đồng ý rõ ràng hoặc các tác phẩm phái sinh của chúng tôi. Sự đồng ý trước bằng văn bản.
Bạn không được đảo ngược kỹ thuật hoặc giải mã bất kỳ phần mềm nào của chúng tôi dưới bất kỳ hình thức nào (ngoại trừ trong phạm vi được luật pháp hiện hành cho phép). Bạn phải thực hiện các bước hợp lý để đảm bảo rằng phần mềm của chúng tôi không bị tiết lộ cho bất kỳ bên thứ ba nào.
Bạn thừa nhận và đồng ý rằng mọi câu hỏi, nhận xét, đề xuất, ý tưởng, phản hồi hoặc thông tin khác liên quan đến Dịch vụ của chúng tôi ("Nội dung gửi") do bạn cung cấp cho chúng tôi đều không bí mật và sẽ trở thành tài sản duy nhất của chúng tôi. Chúng tôi sẽ sở hữu quyền sở hữu trí tuệ độc quyền đối với các Nội dung gửi này và sẽ có quyền sử dụng và phổ biến không hạn chế các Nội dung gửi này cho bất kỳ mục đích nào, thương mại hay mục đích khác mà không cần thừa nhận hoặc bồi thường cho bạn.
Trách nhiệm vv.
Không có nội dung nào trong Thỏa thuận này sẽ giới hạn hoặc loại trừ trách nhiệm pháp lý của chúng tôi đối với tử vong hoặc thương tích cá nhân do sơ suất, xuyên tạc mang tính gian lận hoặc bất kỳ trách nhiệm pháp lý nào không được pháp luật loại trừ hoặc giới hạn theo bất kỳ cách nào.
Bạn phải cho chúng tôi cơ hội hợp lý để khắc phục bất kỳ vấn đề nào mà chúng tôi có thể phải chịu trách nhiệm trước khi bạn tự chịu bất kỳ chi phí nào để khắc phục vấn đề đó.
RẤT QUAN TRỌNG: Chúng tôi sẽ không chịu trách nhiệm về bất kỳ tổn thất hoặc thiệt hại nào do chúng tôi hoặc nhân viên hoặc đại lý của chúng tôi gây ra nếu:
Chúng tôi hoặc bất kỳ nhân viên hoặc đại lý nào của chúng tôi không vi phạm bất kỳ nghĩa vụ chăm sóc pháp lý nào đối với bạn;
Một trong hai bên không thể lường trước được một cách hợp lý sự mất mát hoặc thiệt hại đó;
tổn thất hoặc thiệt hại đó là do bạn gây ra, ví dụ như do không tuân thủ Thỏa thuận này; hoặc
Những mất mát hoặc thiệt hại đó liên quan đến hoạt động kinh doanh.
RẤT QUAN TRỌNG: Bạn sẽ phải chịu trách nhiệm pháp lý về mọi tổn thất hoặc thiệt hại có thể dự đoán trước một cách hợp lý mà chúng tôi phải gánh chịu do bạn vi phạm Thỏa thuận này hoặc sử dụng sai mục đích dịch vụ của chúng tôi (tất nhiên chúng tôi sẽ có nghĩa vụ giảm thiểu mọi tổn thất).

hoãn
Cả bạn và chúng tôi sẽ không chịu trách nhiệm nếu có bất kỳ sự thất bại hoặc chậm trễ trong việc thực hiện bất kỳ nghĩa vụ nào theo Thỏa thuận này (ngoài việc thanh toán) là do bất kỳ trường hợp nào nằm ngoài tầm kiểm soát hợp lý của bạn hoặc của chúng tôi (bao gồm cả sự cố viễn thông của bên thứ ba).
chuyển khoản
Chúng tôi có thể chuyển nhượng toàn bộ hoặc một phần quyền hoặc nghĩa vụ của mình theo Thỏa thuận này nếu chúng tôi thực hiện các bước hợp lý để đảm bảo rằng các quyền của bạn theo Thỏa thuận này không bị suy giảm. Vì Thỏa thuận này mang tính cá nhân đối với bạn nên bạn không được chuyển nhượng bất kỳ quyền hoặc nghĩa vụ nào của mình theo Thỏa thuận này mà không có sự đồng ý trước bằng văn bản của chúng tôi.
lời phàn nàn
Nếu bạn có bất kỳ khiếu nại nào, vui lòng liên hệ với chúng tôi qua thông tin liên hệ được hiển thị trên trang web của chúng tôi

Cam kết bảo mật
về chúng tôi

Chúng tôi cam kết bảo vệ quyền riêng tư và bảo mật của tất cả các cá nhân và rất coi trọng trách nhiệm của mình về bảo mật dữ liệu. Giúp bạn sắp xếp việc mua các sản phẩm cho vay. Điều này bao gồm việc xử lý mọi dữ liệu cá nhân một cách hợp pháp, công bằng và minh bạch.

Nếu bạn quan tâm đến dịch vụ của chúng tôi, chúng tôi sẽ thu thập thông tin gì về bạn? Tại sao?

Với tư cách là bên trung gian, chúng tôi chỉ thu thập thông tin từ bạn như một phần dịch vụ của chúng tôi để có thể giúp sắp xếp các sản phẩm cho vay có thể phù hợp với bạn. Trong ngành dịch vụ tài chính, chúng tôi gọi đây là hoạt động tìm hiểu thực tế và nó cung cấp cho chúng tôi tất cả thông tin bạn cần để cung cấp cho bạn nhà môi giới phù hợp với tình huống và yêu cầu của bạn.

Khi chúng tôi nhận được thông tin từ bạn, chúng tôi sẽ thực hiện qua điện thoại, email hoặc bài đăng và xác nhận tính chính xác của dữ liệu được tổng hợp. Điều quan trọng là tất cả dữ liệu chúng tôi lưu giữ về bạn đều chính xác để chúng tôi có thể giúp sắp xếp các sản phẩm phù hợp cho bạn.

Các loại thông tin chúng tôi sẽ thu thập từ bạn bao gồm: Chi tiết liên hệ, nghề nghiệp, độ tuổi, yêu cầu vay vốn. Ngoài ra, chúng tôi có thể thu thập dữ liệu cá nhân nhạy cảm, chẳng hạn như tình trạng y tế hoặc dữ liệu tội phạm hình sự, nếu cần để giúp bạn sắp xếp khoản vay thay mặt bạn.

Nếu bạn cung cấp thông tin cá nhân của mình trên trang web của chúng tôi, đây sẽ được coi là hành động khẳng định rằng bạn muốn chúng tôi liên hệ với bạn vì các mục đích đã nêu trên trang web của chúng tôi.

Thông tin liên hệ nhận được từ các công cụ tạo khách hàng tiềm năng của bên thứ ba

Đôi khi, chúng tôi có thể nhận được chi tiết liên hệ của bạn từ những người tạo khách hàng tiềm năng bên thứ ba, vui lòng nhấp vào đây để xem danh sách các nhà cung cấp mà bạn đã bày tỏ sự quan tâm đến các sản phẩm/dịch vụ mà chúng tôi cung cấp. Khi chúng tôi sử dụng công cụ tạo khách hàng tiềm năng, chúng tôi sẽ yêu cầu họ phải có sự đồng ý của bạn và nói rõ với bạn rằng họ sẽ chuyển thông tin chi tiết của bạn cho các công ty như chúng tôi và bạn sẽ mong đợi chúng tôi liên hệ với bạn.

Thông tin từ các nguồn khác

Nếu cần, chúng tôi có thể lấy thông tin từ các nguồn khác, chẳng hạn như hồ sơ công khai. Điều này là để chúng tôi có thể xác minh danh tính của bạn hoặc tính chính xác của thông tin bạn cung cấp cho chúng tôi.

Chúng tôi sẽ sử dụng thông tin này như thế nào?

Chúng tôi sẽ chỉ sử dụng dữ liệu của bạn theo cách mà bạn mong đợi một cách hợp lý rằng chúng tôi sẽ sử dụng dữ liệu đó. Dưới đây là tóm tắt về cách chúng tôi sử dụng dữ liệu của bạn.

Chúng tôi sẽ sử dụng thông tin bạn cung cấp để thay mặt bạn nghiên cứu thị trường và tìm nhà môi giới phù hợp với tình hình và nhu cầu của bạn. Sau khi nghiên cứu xong, chúng tôi sẽ giới thiệu cho bạn một nhà môi giới phù hợp và cung cấp cho bạn báo giá được cá nhân hóa. Trong khi bạn đang xem xét báo giá, chúng tôi có thể giữ liên lạc với bạn để giúp bạn sắp xếp thêm các báo giá so sánh nếu bạn muốn.

Chúng tôi cũng sẽ sử dụng dữ liệu của bạn để hỗ trợ nhà môi giới với bất kỳ khiếu nại nào bạn có thể có liên quan đến khoản vay của mình hoặc xử lý các khiếu nại nếu bạn không hài lòng với dịch vụ mà bạn nhận được.

Để cải thiện dịch vụ của chúng tôi, các cuộc gọi có thể được theo dõi và ghi lại cho mục đích đào tạo và kiểm tra cũng như để giúp chúng tôi xử lý các khiếu nại hoặc khiếu nại. Bạn sẽ nhận được thông báo nơi chúng tôi ghi âm cuộc gọi.

Cơ sở hợp pháp của chúng tôi để sử dụng thông tin này

Cách chúng tôi sử dụng thông tin của bạn (như mô tả ở trên), chúng tôi sẽ sử dụng thông tin của bạn dựa trên lợi ích hợp pháp của chúng tôi. Chúng tôi quyết định phát triển dựa trên điều này vì nó cho phép chúng tôi đáp ứng các quy định của ICO và FCA, đồng thời là cơ sở pháp lý phù hợp nhất để xử lý dữ liệu nhằm thu xếp các khoản vay.

Bạn có quyền gì?

Bạn có quyền:

Tìm hiểu cách chúng tôi sử dụng, chia sẻ và lưu trữ thông tin cá nhân của bạn;
Yêu cầu quyền truy cập vào dữ liệu cá nhân mà chúng tôi lưu giữ về bạn (còn được gọi là Yêu cầu truy cập chủ đề (SAR)).
Nếu có yêu cầu SAR, chúng tôi sẽ phản hồi nhanh chóng trong vòng một tháng kể từ ngày yêu cầu;
Yêu cầu chỉnh sửa dữ liệu cá nhân của bạn nếu dữ liệu đó không chính xác hoặc không đầy đủ;
Yêu cầu xóa dữ liệu cá nhân của bạn khi không có lý do thuyết phục để tiếp tục xử lý dữ liệu cá nhân của bạn và chúng tôi không có lợi ích chính đáng trong việc giữ lại dữ liệu cá nhân của bạn;
Yêu cầu hạn chế xử lý dữ liệu của bạn;
Quyền phản đối việc xử lý dữ liệu cá nhân của bạn;
Các quyền liên quan đến việc ra quyết định và lập hồ sơ tự động.
Nếu việc xử lý dữ liệu của bạn dựa trên sự đồng ý của bạn, bạn có quyền rút lại sự đồng ý đó bất cứ lúc nào bằng cách liên hệ với chúng tôi qua điện thoại hoặc email. Chúng tôi không sử dụng hệ thống lập hồ sơ hoặc ra quyết định tự động.

tiếp thị

Nếu chúng tôi yêu cầu sự đồng ý của bạn, chúng tôi sẽ yêu cầu sự đồng ý của bạn một cách riêng biệt. Chúng tôi không sử dụng các ô được đánh dấu sẵn hoặc cho rằng bạn đã đồng ý. Bạn phải tự do đưa ra sự đồng ý của mình bằng cách chủ động lựa chọn hoặc thực hiện hành động khẳng định rõ ràng để bày tỏ sự đồng ý của mình. Chúng tôi sẽ cố gắng hết sức để đảm bảo rằng bạn biết chính xác những gì bạn đồng ý và nhắc nhở bạn rằng bạn có thể rút lại sự đồng ý của mình bất cứ lúc nào bằng cách liên hệ với chúng tôi qua email hoặc điện thoại. Nếu có sự đồng ý, chúng tôi sẽ ghi lại điều này để xác nhận những gì bạn đã đồng ý, ngày giờ và cách thức có được sự đồng ý đó.

Khách hàng tiềm năng: Nếu bạn bày tỏ sự quan tâm đến một sản phẩm nhưng sau đó quyết định không tiếp tục, chúng tôi muốn giữ liên lạc với bạn và do đó sẽ yêu cầu sự đồng ý của bạn trong trường hợp bạn có thể quan tâm đến sản phẩm đó trong tương lai.

Không phải là khách hàng: Chúng tôi sẽ chỉ gửi cho bạn thông tin về các sản phẩm hoặc dịch vụ được quản lý khi có sự đồng ý của bạn.

Thay đổi thông tin

Chúng tôi thường xuyên xem xét và cập nhật tuyên bố về quyền riêng tư của mình khi cần thiết. Nếu chúng tôi dự định sử dụng dữ liệu cá nhân cho các mục đích mới, tuyên bố về quyền riêng tư của chúng tôi sẽ được cập nhật và bạn sẽ được thông báo.`,
      altText: "图片",
    };
  },
};
</script>

<style scoped>
.imgBox {
  display: flex;
  flex-direction: column;
  background-color: #FFFFFF;
}
.imgBox1
{
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}
.imgBox2 {
  display: flex;
  justify-content: center;
  max-width: 1400px;
  padding-top: 40px;
  padding-bottom: 20px;
  height: 5100px;
  background-color: #FFFFFF;
  border-radius: 32px;
  margin: 0 auto 150px;
}

.imgBox3 {
  display: flex;
  justify-content: center;
  width: 100%;
  min-width: 1400px;
  background-color: "";
}

img {
  display: block;
}
.img1 {
  overflow: hidden;
  width: 100%;
}

.img2,
.img4 {
  overflow: hidden;
}

.img3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
.imgItem1 {
  width: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.imgItem2 {
  max-width: 100%;
}
.imgItem3 {
  width: 100%;
  overflow: hidden;
  margin-left: 0;
  margin-right: 0;
}
h1{
  /* margin-top: 50px; */
 margin-left: 490px;
 color: #333333;
 font-size: 40px;
/* border-bottom: 5px solid #04358A; */
display: inline-block
}
.textBox{
  margin-top: 50px;
  margin-left: 100px;
  margin-right: 100px;
  font-size: 18px;
  color: #333333;
}
.img2{
  margin: 0 50px;
}
</style>